const React = require('react')
require('prismjs/plugins/line-numbers/prism-line-numbers.css')
require('./assets/prismThemes/highlight.css')
require('./assets/prismThemes/prism-meadow-dark.css')

const { ThemeProvider } = require('./src/providers/ThemeProvider')

exports.wrapRootElement = ({ element, props }) => {
  return <>
    <ThemeProvider {...props}>
      {element}
    </ThemeProvider>
  </>
}

// Implement persisted layout here
exports.wrapPageElement = ({ element, props }) => {
  return element
}