import React, { useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import colors from '../styles/colors'



export interface ITheme {
  primary: string
  secondary: string
  accent: string
  text: string
  oppositeTheme?: ITheme
}

interface IThemeState {
  theme: ITheme
  darkMode: boolean
  toggleDarkMode: () => void
}

let DARK_THEME: ITheme = {
  primary: colors.darkBlue,
  secondary: colors.darkerBlue,
  text: colors.white,
  accent: colors.teal,
}

const LIGHT_THEME: ITheme = {
  primary: colors.white,
  secondary: colors.white,
  text: colors.black,
  accent: colors.teal,
  oppositeTheme: DARK_THEME
}

DARK_THEME.oppositeTheme = LIGHT_THEME

const ThemeState = (): IThemeState => {
  const [darkMode, setDarkMode] = useState(true)

  const theme: ITheme = useMemo(() => {
    if (darkMode) return DARK_THEME
    else return LIGHT_THEME
  }, [darkMode])

  return {
    darkMode,
    toggleDarkMode: () => setDarkMode(d => !d),
    theme
  }
}

const ThemeContainer = createContainer(ThemeState)
export const useTheme = ThemeContainer.useContainer

const ThemeWrapper = (props: any) => {
  const { theme } = useTheme()
  return <SCThemeProvider theme={theme} {...props} />
}

export const ThemeProvider = (props: any) => {
  return <ThemeContainer.Provider initialState={props.initialState}>
    <ThemeWrapper {...props} />
    </ThemeContainer.Provider>
}
